<template>
    <div class="container-geral">
        <div class="container">
            <form action="" id="formLogin" @submit.prevent="validaUsuario">
                <div class="container-usuario">
                    <div id="logoLoja">
                        <img src="../assets/img/logo-minus.png" alt="logo" />
                    </div>
                    <div class="label-usuario">
                        <span class="usuario"> Usuário * </span>
                    </div>
                    <div class="input-usuario">
                        <input :class="{ 'input-invalid': isInvalid }" type="text" v-model="usuario" required />
                    </div>
                </div>
                <div class="container-senha">
                    <div class="label-senha">
                        <span class="senha"> Senha * </span>
                    </div>
                    <div class="input-senha">
                        <input :class="{ 'input-invalid': isInvalid }" type="password" v-model="senha" required />
                    </div>
                </div>
                <small v-if="isInvalid" class="usuario-invalido">Usuário ou senha inválidos.</small>
            </form>
        </div>
        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <button class="btn-update" v-if="isRefresh" @click="update">Atualizar App</button>
    </div>
</template>

<script>
import HTTP from '../http/http.js';
import MsgSucess from '../components/MsgSucess.vue';
// const crypto = require('crypto'); // Primeiro importar

export default {
    data() {
        return {
            usuario: '',
            senha: '',
            isInvalid: false,
            showSucess: false,
            msgS: 'App atualizado com sucesso!',
            msgInvalid: '',
            registration: null,
            isRefresh: false,
            refreshing: false,
        };
    },

    components: {
        MsgSucess,
    },

    created() {
        document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, { once: true });
    },

    methods: {
        update() {
            this.isRefresh = false;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
            window.location.reload(true);
        },

        appUpdateUI: function (e) {
            this.registration = e.detail;
            this.isRefresh = true;
        },

        validaUsuario() {
            if (this.usuario == '' || this.senha == '') {
                this.isInvalid = true;
                this.msgInvalid = 'Preencha o usuário e senha!';
            }

            let senha = this.senha.toLowerCase();
            // senha = crypto.createHash('sha1').update(senha).digest('hex');

            let data = {
                nome: this.usuario,
                senha: senha,
                novaAutenticacao: true,
            };

            HTTP.post(`login`, data)
                .then((response) => {
                    let usuario = response.data;
                    sessionStorage.setItem('usuario', usuario.nome);
                    sessionStorage.setItem('loja', usuario.loja);

                    this.$router.push({ name: 'carregamento' });
                })
                .catch(() => {
                    this.isInvalid = true;
                    this.msgInvalid = 'Login ou senha inválidos, favor tentar novamente.';
                });
        },
    },
};
</script>

<style scoped>
.btn-update {
    width: 50%;
    align-self: center;
    justify-self: center;
    background-color: var(--verde);
    padding: 10px 5px;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    transition: all 1s;
}

.btn-update:hover {
    position: relative;
    top: 3px;
    left: 3px;
}

.container-geral {
    display: grid;
}

#logoLoja {
    display: flex;
}

#logoLoja img {
    width: 100px;
}

.container {
    padding: 10px 20px;
    margin-top: 56px;
    box-shadow: 0 0 5px #cdcdcd;
    padding-bottom: 25px;
    margin-bottom: 63px;
}

form div {
    padding: 5px 0;
}

form div div span {
    font-size: 0.8rem;
    color: #5f5e5e;
}

form div div input {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

form div div input:focus {
    outline-color: var(--verde);
}

.usuario-invalido {
    font-size: 0.7rem;
    color: red;
}

.input-invalid {
    border-color: red;
}
</style>
